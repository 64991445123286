<template>
  <div>
    <div v-if="!pageLoaded" style="padding-top:130px; height: 280px;">
      <div class="loader" style="text-align: center;"></div>
    </div>
    <div v-else style="padding-top: 56px">
      <div :class="'mobileNavBar ' + deviceType">
        <div class="appBar-header">
          <div @click="routeBack('/',false)" class="header-route-back">
        <span v-if="deviceType === 'ios'">
          <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
            <span v-else>
          <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
          </div>
          <div class="header-title-container">
            <p class="header-title">
              Destek Merkezi
            </p>
          </div>
          <div class="tickets">

            <router-link to="/tickets" style="text-decoration: none">
              <img src="https://gcdn.bionluk.com/site/icon/ic_add.svg" alt="">
            </router-link>
          </div>
        </div>
      </div>


      <router-link to="/tickets" style="color: #fd4056; font-size: 14px; float: right; margin-right: 16px;">Tüm taleplerim</router-link>
      <div class="hiddendiv10" style="clear: both;"></div>
      <div class="support-categories">
        <ul v-for="(item, index)  in supportCategories" class="support-category-list">
          <li class="support-category-list-item-header">{{ item.title }}</li>
          <li v-for="(sub_cat, is) in item.sub_categories" class="support-category-list-item">
            <div class="support-category-list-item-detail" @click="$router.push(`${prefixPath}destek/kategori/${sub_cat.slug}`)">
              <div class="support-category-list-item-detail-text">
                <img src="https://gcdn.bionluk.com/site/icon/icon_placeholder.svg" style="height: 20px;">
                <p class="support-category-list-item-detail-name">{{ sub_cat.title }}</p>
              </div>

              <div>
                <img src="https://gcdn.bionluk.com/site/icon/tansel_mobil_ws_ok.svg" alt="">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import FooterNav from "../../../../components/shared/navFooter";


  export default {
    name: "src-pages-body-staticContents-support-list-v1_mobile",
    components: {FooterNav},
    data() {
      return {
      	pageLoaded: false,
        supportCategories: [{
      	  sub_categories:[]
        }],
        supportTopicName: null,
        supportTopics: [],
        topicLoaded: true,
        selectedTopic: null,
        selectedCategory: {}
      };
    },

    methods: {
      fetchDataToInit() {
        this.api.content.retrieveSupportCategoriesv2()
        .then(({data})=> {
          let result = data;

          if (result.success) {
            this.supportCategories = result.data.support_categories;

            this.pageLoaded = true;

          } else {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.$router.push(result.redirect_url);
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
      }
    },

    created() {
      this.fetchDataToInit();
    }
  }
</script>

<style scoped lang="scss">
  .bread{
    font-size: 14px;
    color: #2d3640;
  }

  .bread span{
    color: #b2bcc8;
    text-decoration: none!important;;
  }

  .support-category-list{
    display: flex;
    flex-direction: column;
  }

  .support-category-list-item{
    padding: 19px 0;
    border-bottom: 1px solid #dfe4ec;
    padding-right: 5px;
  }

  .support-category-list-item-detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .support-category-list-item-detail-text{
    display: flex;
    color: #8b95a1;
  }

  .support-categories{
    padding: 10px 15px 50px 15px;
  }

  .support-category-list-item-detail-name{
    padding-left: 14px;
  }

  .support-category-list-item-header{
    font-weight: 600 !important;
    color: #242424;
    padding: 5px 0 5px 15px;
  }
</style>
